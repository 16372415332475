<template>
  <div class="honeycomb">
    <div class="container">
      <div class="row">
        <div class="col" style="display: flex; justify-content: center;">
          <div id="toastMsg"></div>
            <div class="toast" id="mytoast" style="max-width:140px;" role="alert" ref="toast1" aria-live="assertive" aria-atomic="true">
              <div class="toast-body" style="display: flex; max-height: 37px; vertical-align: middle;">{{ points_label }} &nbsp; <b>{{ points }}</b></div>
            </div>
        </div>
        <div class="col" style="height: 40px;">
            <div class="row align-items-center" @click="this.showRankingsModal();" style="cursor: pointer;">
              <div class="col-2">
                <span style="width: 50px; font-weight: bold; display: inline; vertical-align: text-top">{{ score_level_label }}</span>
              </div>
              <div class="col">
                <div class="progress" style="height: 18px;">
                  <div class="progress-bar" role="progressbar" style="background-color: #fce303; color: black; font-weight: bold;" :style="{width: score_level_pct + '%'}" aria-valuenow="{{ score_level_pct}}" aria-valuemin="0" aria-valuemax="100">{{ score }}</div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <div class="row">
          <div class="col">
            <div contenteditable="true" id="user_guess" ref="entered_word" v-on:keydown.space.prevent v-on:keydown="removeBr" v-on:keyup.enter="hasWord" size="25" maxlength="20" autofocus placeholder="Type a word"></div>
            <div class="hive">
              <svg
                class="hive-cell center"
                viewBox="0 0 60 52">
                <polygon
                  class="cell-fill"
                  points="0,26 15,0 45,0 60,26 45,52 15,52"
                  :stroke="white"
                  stroke-width="7" />
                <text class="cell-letter" x="50%" y="50%" dy="10.75%">
                  {{ puzzle.required_letter }}
                </text>
              </svg>
              <svg
                v-for="(letter, index) in puzzle.letters"
                :key="index"
                class="hive-cell outer"
                viewBox="0 0 60 52">
                <polygon
                  class="cell-fill"
                  points="0,26 15,0 45,0 60,26 45,52 15,52"
                  :stroke="white"
                  stroke-width="7.5" />
                <text class="cell-letter" x="50%" y="50%" dy="10.75%">
                  {{ letter }}
                </text>
              </svg>
            </div>
          </div>
          <div class="col rounded" id="foundwords">
            <div class="row foundWordsCount">
              <p>Has encontrado {{ user_found_words.length }} palabras</p>
            </div>
            <div class="row">
              <div class="col-sm">
                <div v-for="(word, index) in user_found_words" :key="index">
                  <div v-if="isPanagram(word)" class="row foundWordList panagram">{{ word }}</div>
                  <div v-else class="row foundWordList">{{ word }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="modal fade" role="dialog" id="rankingsModal" ref="rankingsModal">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header" style="border-bottom: none; padding-bottom: 10px;">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" style="padding-top: 0px; padding-left: 30px; padding-right: 30px;">
            <h2 style="font-weight: bold; font-family:'American Typewriter';">Clasificaciones</h2>
            <p>Los rangos se basan en un porcentaje de puntos posibles en un rompecabezas. Los puntajes mínimos para alcanzar cada rango para el día de hoy son:</p>
            <br/>
            <p v-for="(name, level) in score_levels" :key="level"  style="line-height: .5;" >
              <b>{{ name }}</b> ({{ level }})
            </p>
            <br/>
            <p>¿Crees que nos perdimos una palabra? Envíenos un email a <a href="palabras@abejorroreina.com">palabras@abejorroreina.com</a></p>
            <p>¿Tiene comentarios? Envíenos un email a <a href="mailto: juegos@abejorroreina.com">juegos@abejorroreina.com</a></p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" role="dialog" id="welcomeModal" ref="welcomeModal">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content" id="welcomeContent">
          <div class="modal-body text-center" style="padding-top: 15%;">
            <img src="../assets/white_wings.png" height="75">
            <div v-if="user_found_words.length > 0">
              <p style="font-weight: bold; font-size: xxx-large; font-family:'American Typewriter'; margin-bottom: 0rem;">Bienvenido de Nuevo</p>
              <p style="font-size: xx-large; font-family:'American Typewriter'; margin-bottom:2rem;">Has encontrado {{ user_found_words.length }} palabras.</p>
              <button type="button" class="btn btn-dark rounded-5 btn-lg" data-bs-dismiss="modal" style="width:140px;"><b>Seguir</b></button>
            </div>
            <div v-else>
              <p style="font-weight: bold; font-size: xxx-large; font-family:'American Typewriter'; margin-bottom: 0rem;">Abejorro Reina</p>
              <p style="font-size: xx-large; font-family:'American Typewriter'; margin-bottom:2rem;">¿Cuántas palabras puedes<br/> formar con 7 letras?</p>
              <button type="button" class="btn btn-dark rounded-5 btn-lg" data-bs-dismiss="modal" style="width:140px;"><b>Juego</b></button>
            </div>
            <br/>
            <p style="margin-top: 1rem; font-weight: bold;">{{ currentDate() }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import {Toast} from 'bootstrap'
import {Modal} from 'bootstrap'

export default {
  name: 'HoneyComb',
  props: {
    panagram: String,
    required_letter: String,
    toastVisible: Boolean
  },
  data() {
    return {
      puzzle: [],
      entered_word: "",
      user_found_words: [],
      score: "",
      score_level_pct: "",
      score_level_label: "",
      score_levels: [],
      points: 0,
      points_label: ""
    }
  },
  mounted() {
    this.showWelcomeModal()
    this.getLetters()
  },
  methods: {
    showRankingsModal() {
      const rankingsModal = new Modal('#rankingsModal')
      rankingsModal.show()
    },
    showWelcomeModal() {
      const welcomeModal = new Modal('#welcomeModal')
      welcomeModal.show()
    },
    getLetters() {
      axios.get(process.env.VUE_APP_BASE_URL + '/getPuzzle')
          .then((response) => {
            console.log(response.data)
            this.puzzle = response.data
            this.letters = this.puzzle.letters
            this.user_found_words = this.puzzle.user_found_words
            this.score = this.puzzle.score
            this.score_level_pct = parseInt(this.puzzle.score_level_pct)
            this.score_level_label = this.puzzle.score_level_label
            this.score_levels = this.puzzle.score_levels
          })
          .catch((error) => {
            console.log(error)
          })
    },
    showToast() {
      const toastEl = this.$refs.toast1
      let aToast = new Toast(toastEl, { autohide: true, animation: false, delay: 1300 })
      aToast.show()
    },
    removeBr(event) {
          if (event.which === 13) {
        event.preventDefault();
    }
    },
    hasWord() {
      console.log('input word is' + this.$refs.entered_word.textContent)
      console.log('req letter: ' + this.puzzle.required_letter)
      let word = this.$refs.entered_word.textContent.toUpperCase()
      if (word.length < 4) {
        this.points_label = 'Demasiado Corta';
        this.points = '';
        this.showToast()
      }
      else if (! word.includes(this.puzzle.required_letter)) {
        this.points_label = 'Falta Letra Centro';
        this.points = '';
        this.showToast()
      }
      // already found
          //bad letters
          //not in word list
      else {
        axios.get( process.env.VUE_APP_BASE_URL + '/hasWord?word=' + word)
          .then((response) => {
            console.log(response.data)
            this.user_found_words = response.data.user_found_words
            // let points = response.data.points
            this.score = response.data.score
            this.score_level_pct = parseInt(response.data.score_level_pct)
            this.score_level_label = response.data.score_level_label
            this.points = response.data.points
            this.points_label = response.data.points_label
            this.showToast()
          })
          .catch((error) => {
            console.log(error)
          })
      }
      this.$refs.entered_word.textContent = ' '
    },
    isPanagram(word) {
      // console.log('word is: ' + word)
      // console.log('panagram is: ' + this.puzzle.panagram)
      if (word.toUpperCase() == this.puzzle.panagram)
        return true
      else
          return false
    },
    currentDate() {
      const current = new Date();
      let month = current.toLocaleString('es-ES', { month: 'long' });
      month = month.charAt(0).toUpperCase() + month.slice(1);
      const day = current.getDate();
      const year = current.getFullYear()
      return day + ' de ' + month + ', ' + year;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
[contenteditable] {
  outline: 0px solid transparent;
}
#user_guess:empty:before {
  content: 'Escriba una palabra';
  caret-color: white;
  font-weight: 600;
  color: lightgray;
  text-transform: none;
}
#welcomeContent {
  background-color: #fce303 !important;
}
#user_guess {
  border: none;
  caret-color: gold;
  font-size: 24pt;
  text-align: center;
  font-weight: bold;
  height: 1.25em;
  line-height: 1.25;
  text-transform: uppercase;
  margin-top: 25px;
}
input {
  border: none;
  caret-color: gold;
  font-size: 24pt;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}
.foundWordList {
  color: black;
  text-transform: capitalize;
  border-bottom: 1px solid lightgray;
  margin-left: 15px;
  margin-bottom: 7px;
  font-size: 16px;
  padding-left: 0px;
  width: calc(33.33% - 25px);
}
.panagram {
  font-weight: 700;
}
#foundwords {
  border: 1px solid lightgray;
}
.foundWordsCount {
  text-align: left;
  margin-top: 10px;
  margin-left: 0px;
  font-size: 16px;
}
.hive {
  position: relative;
  left: 137px;
  width: 100%;
  padding-bottom: 100%;
}
.hive-cell {
  position: absolute;
  top: calc(70% / 3);
  left: 15%;
  width: 20%;
  height: calc(50% / 3);
  padding-bottom: 6px;
}
.hive-cell .cell-fill {
  cursor: pointer;
  fill: #e6e6e6;
  transition: all 100ms;
}
.hive .cell-letter {
  font-weight: 900;
  font-size: 14px;
  text-anchor: middle;
  text-transform: uppercase;
  pointer-events: none;
}
.hive-cell:first-child .cell-fill {
  cursor: pointer;
  fill: #fce303;
  transition: all 100ms;
  z-index: 5;
}
.hive-cell:nth-child(1) {
  transform: translate(0, 0);
}
.hive-cell:nth-child(2) {
  transform: translate(-75%, -50%);
}
.hive-cell:nth-child(3) {
  transform: translate(0, -100%);
}
.hive-cell:nth-child(4) {
  transform: translate(75%, -50%);
}
.hive-cell:nth-child(5) {
  transform: translate(75%, 50%);
}
.hive-cell:nth-child(6) {
  transform: translate(0, 100%);
}
.hive-cell:nth-child(7) {
  transform: translate(-75%, 50%);
}
polygon.cell-fill:active {
  transform: scale(0.9);
}
polygon.cell-fill {
  transition: all 1s;
  transform-origin: 50% 50%;
}

</style>
