<template>
  <nav class="navbar bg-light" style="border-bottom:1px solid darkgray">
    <div class="d-flex justify-content-start" style="padding-left: 20px;">
      <img src="./assets/Bee.png" alt="Logo" height="55">
      <span style="font-family:'American Typewriter';font-size:36px; padding-left: 20px;">
        <b>Abejorro Reina</b>
      </span>
      <span style="font-size:24px; padding-left: 30px; padding-top: 13px;">{{ currentDate() }}</span>
    </div>
  </nav>
  <br>
  <HoneyComb/>
</template>

<script>
import HoneyComb from './components/HoneyComb.vue'

export default {
  name: 'App',
  components: {
    HoneyComb
  },
  methods: {
    currentDate() {
      const current = new Date();
      let month = current.toLocaleString('es-ES', { month: 'long' });
      month = month.charAt(0).toUpperCase() + month.slice(1);
      const day = current.getDate();
      const year = current.getFullYear()
      return day + ' de ' + month + ', ' + year;
    }
  }
}
</script>


<style>

#app {
  font-family: Avenir, Helvetica, Arial, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;

}
</style>
